import Vue from 'vue';
import Vuex from 'vuex';
// import App from '../main';
// import Utils from '../utils/index';


Vue.use(Vuex);
// 初始化store
const sxwStore = new Vuex.Store({
  modules: {},
  state: {
    // 用户信息，全局使用

    // 组件使用
    globalLoadFlag: [],
    globalLoad: {
      loading: false,
      text: '玩命加载中...'
    }
  },
  mutations: {

    // 全局loading
    setGlobalLoad(state, load) {
      state.globalLoad = load;
    },

    // 默认API请求的全局loading
    setDefaultLoading(state, load) {
      let { loading } = load;
      if (loading) {
        state.globalLoadFlag.push(true);
      } else {
        state.globalLoadFlag.pop();
        if (state.globalLoadFlag.length !== 0) {
          load.loading = true;
        }
      }
      state.globalLoad = load;
    },

    // ######## 统一处理api错误 ########
    ajaxErrorMessage(state, data) {
      let { errorCode, message } = data;
      console.info('[-> store.js] ajaxErrorMessage', errorCode, message);
      // App.$message.error(Utils.getAjaxErrorMessageToUsers(errorCode, message));

      // state.ajaxErrorLogs.push(data);
    },

    // 路由重定向
    ajaxErrorRedirect(state, data) {
      console.info('[-> store.js] ajaxErrorRedirect 路由重定向 [暂未处理]::', data);
      return false;
    }
  }
});


console.log("\n%c--------------------STORE--------------------", "color:#989a9d;");
console.log('[=> store.js]', sxwStore);
window.STORE = sxwStore;

export default sxwStore;
