<template>
  <div id="app">
    <router-view v-if="isWeixin" />
    <div v-else style="padding: 4rem 0; text-align: center; font-size: 0.24rem; color: #999">请使用微信浏览器打开</div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
    };
  },
  computed: {
    isWeixin() {
      return true;
      // return (this.$route.query.tester || localStorage.getItem('_tester')) || window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) === 'micromessenger';
    },
  },
}
</script>
