import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/home'
  },
  {
    // 首页
    name: 'home',
    component: () => import('./view/home.vue')
  },
  {
    // 主页（四大卡）
    name: 'card',
    component: () => import('./view/card')
  },
  {
    // 卡二级页面（衣、食、住、行等（1~10））
    name: 'mall',
    component: () => import('./view/mall')
  },
  {
    // 卡二级页面（全部（11））
    name: 'show_all',
    component: () => import('./view/show_all')
  },
  {
    // 活动页面（三级）
    name: 'activity',
    component: () => import('./view/activity')
  },
  {
    // 活动门店（商圈）
    name: 'shop_list',
    component: () => import('./view/shop_list')
  },
  {
    // 门店列表（商圈）
    name: 'shop_detail',
    component: () => import('./view/shop_detail')
  },
  {
    // 图片展示页面（单独）
    name: 'show_img',
    component: () => import('./view/show_img')
  },
  {
    // 主要用于上面链接展示图片，避免跳转不起效
    name: 'show_img2',
    component: () => import('./view/show_img2')
  },
  {
    // 地图展示（单独）
    name: 'map',
    component: () => import('./view/map')
  }, {
    // 特殊列表页面
    name: 'otherMall',
    component: () => import('./view/other_all')
  }, {
    // 特殊列表页面
    name: 'coupons',
    component: () => import('./view/coupons')
  }, {
    // 特殊列表页面
    name: 'branches',
    component: () => import('./view/branches')
  }
  , {
    // 新食和购列表
    name: 'subList',
    component: () => import('./view/sub_list')
  }, {
    //展示架构2023-12-15
    name: 'subListNew',
    component: () => import('./view/sub_list_new')
  }, {
    //展示架构2023-12-15
    name: 'subOtherId',
    component: () => import('./view/sub_list_other_id.vue')
  },{
    //展示架构2024-02-26
    name: 'showLabel',
    component: () => import('./view/show_label.vue')
  },
  {
    //展示架构2024-02-26
    name: 'showLabel2',
    component: () => import('./view/show_label.vue')
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
  // mode: 'history',
  base: 'page',
  routes
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  next();
});

router.afterEach(() => {
  // 让页面回到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

export {
  router
};
