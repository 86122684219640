import Vue from 'vue';
import App from './App';
import 'amfe-flexible';
import './status/css/common.less';
import 'animate.css';
import { router } from './router';

import {
  Button,
  Divider,
  Tag,
  Col,
  Row,
  Icon,
  Cell,
  Overlay,
  Popup,
  Picker,
  Swipe,
  SwipeItem,
  DropdownMenu,
  DropdownItem,
  Search,
  Toast,
} from 'vant';

Vue.use(Button);
Vue.use(Divider);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(Overlay);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Search);
Vue.use(Toast);


import Ajax from './api/ajax';
Vue.prototype.$ajax = Ajax;
new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
